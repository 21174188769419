.container {
  width: 100%;
  border-radius: 20px;
  background: #FFFFFF;
  padding: 22px 18px 27px 18px;
  text-align: center;

  .userName {
    font-family: DM Sans, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 21px;
    color: #363636; }

  .metaInfo {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #9597A1; }

  .descriptionBox {
    margin: 22px 0;
    text-align: left;
    word-break: break-word; }
  .commentBody {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
    color: #000000; }

  .delim {
    width: 100%;
    height: 1px;
    background: #EBEBEB; }

  .photo {
    cursor: pointer;
    object-fit: cover;
    border-radius: 16px;
    width: 100%;
    max-height: 550px;
    &:hover {
      outline: none; }
    &:focus {
      outline: none; } }

  .playIcon {
    position: absolute;
    left: calc(50% - 75px);
    top: calc(50% - 75px);
    color: white;
    width: 150px; }

  .videoOverlay {
    position: relative;
    cursor: pointer;
    &::before {
      content: '';
      background: rgba(0, 0, 0, 0.3);
      left: 0;
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 16px;
      max-height: 550px; } }

  .avatar {
    border-radius: 50%; }

  .shareBox {
    width: 50px;
    height: 50px;
    box-sizing: border-box;
    background-color: rgba(149, 151, 169, 0.1);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color;
    transition-duration: 1s;

    &:hover {
      cursor: pointer;
      background-color: rgba(149, 151, 169, 0.4); } }

  .shareBar {
    padding: 6px 22px 6px 22px;
    box-sizing: border-box;
    background: #F6F6F6;
    border-radius: 100px; } }
