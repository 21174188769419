.bar {
  width: 24%;
  position: fixed;
  padding-top: 24px;
  background: #FFFFFF;
  border-radius: 20px;
  @media (max-width: 1300px) {
    padding-top: 0;
    position: relative;
    margin: 0 auto 20px;
    min-width: 340px;
    width: 70%; } }

.leftBar {
  @extend .bar;
  padding: 40px;
  box-sizing: border-box; }

.rightBar {
  @extend .bar;
  padding: 25px;
  right: 6%;
  @media (max-width: 1300px) {
    right: 0!important; } }

.title {
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  text-align: left;
  color: #13267E;
  margin-left: 12px; }

.centerBox {
  padding: 23px 35px;
  box-sizing: border-box;
  background: #FFFFFF;
  border-radius: 20px;
  min-height: 606px;
  width: 40%;
  min-width: 340px;
  margin: 0 auto 50px;
  @media (max-width: 1300px) {
    margin: 0 auto 20px;
    width: 70%;
    min-height: initial; } }

.skeletonWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 33px; }
