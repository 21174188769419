.publicationsContainer {
  display: flex;
  padding: 24px 6%;
  width: 100%;
  height: max-content;
  @media (max-width: 1300px) {
    flex-direction: column;
    align-items: center;
    height: initial; }

  -ms-overflow-style: none {
    scrollbar-width: none; } }

::-webkit-scrollbar {
  width: 0;
  background: transparent; }


.skeletonWrapper {
  display: grid;
  align-items: center; }

.centerBox {
  padding: 23px 35px;
  box-sizing: border-box;
  width: 40%;
  min-width: 340px;
  min-height: 606px;
  margin: 0 auto 50px;
  @media (max-width: 1300px) {
    margin: 0 auto 20px;
    width: 70%; }
  background: #FFFFFF;
  border-radius: 20px;
  height: max-content;
  @media (max-width: 1300px) {
    width: 70%;
    order: 2; } }

.avatar {
  border-radius: 100px; }

.friendsContainer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 30px;

  &::after {
    content: "";
    flex: auto; } }

.bar {
  width: 24%;
  position: fixed;
  top: 100px;
  padding-top: 24px;
  @media (max-width: 1300px) {
    padding-top: 0;
    position: initial;
    margin: 0 auto 20px;
    min-width: 340px;
    width: 70%; } }

.leftBar {
  @extend .bar;
  @media (max-width: 1300px) {
    left: 0; } }


.rightBar {
  @extend .bar;
  right: 6%;
  @media (max-width: 1300px) {
    right: 0!important; } }
