.root {
  padding: 35px 90px 35px 90px; }

.container {
  display: flex;
  align-items: flex-start;
  border-radius: 20px;
  max-width: 85%;
  margin: 0 auto 60px;
  background: #FFFFFF; }

.rightBox {
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px 15px 28px;
  min-width: 300px;

  .commentBox {
    padding: 0 25px 20px 30px;
    max-height: 92%;
    overflow-y: auto;
    &::-webkit-scrollbar {
      display: none; }

    .scrollArea {
      padding-right: 24px; } } }

.field {
  width: 100%;
  border: none;
  outline: none;
  background: #F3F3F3;
  border-radius: 50px;
  padding: 25px 30px;
  box-sizing: border-box; }

.playIcon {
  position: absolute;
  left: calc(50% - 75px);
  top: calc(50% - 75px);
  color: white;
  width: 150px; }

.videoOverlay {
  position: relative;
  cursor: pointer;
  &::before {
    content: '';
    background: rgba(0, 0, 0, 0.3);
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 16px;
    max-height: 550px; } }
