.peopleItem {
  width: 100%;

  &:not(:last-child) {
    margin-bottom: 25px; }
  .link {
    line-height: 20px;
    margin-bottom: 2px;
    text-decoration: none; }
  .name {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #363636;
    &:hover {
      text-decoration: underline; } }
  .subText {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #C4C4C4;
    line-height: 14px;
    word-break: break-word; }
  .avatar {
    border-radius: 50px; }
  .button {
    min-width: 91px;
    max-width: 91px;
    min-height: 40px;
    padding: 10px 0px;
    text-align: center !important;
    border-radius: 100px;
    border: none;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 0.01em;
    color: #FFFFFF;
    cursor: pointer;
    transition: background-color;
    transition-duration: .7s;
    background-color: #098B57;
    &:hover {
      background-color: #15593E; }
    &:focus {
      outline: none; } } }
