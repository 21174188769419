.policyContainer {
  text-align: center;
  font-size: 14px;
  margin-top: 40px; }

.underlineText {
  position: relative;
  font-family: DM Sans, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #363636;
  line-height: 25px;
  &:after {
    content: '';
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 1px;

    border-width: 0 0 1px;
    border-style: solid; }
  &:hover {
    border-bottom: 0px solid #0056b3;
    text-decoration: none; } }
