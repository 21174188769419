.container {
  padding: 25px 100px;
  box-sizing: border-box;

  .title {
    font-family: DM Sans;
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 47px;
    color: #13267E; } }
