.container {
  width: 100%;
  min-width: 700px;
  height: 100vh;
  display: flex;
  @media (max-width: 1100px) {
    justify-content: center;
    align-items: flex-start; } }

.formContainer {
  width: 75%;
  //max-width: 550px
  padding: 45px 50px 35px 50px;
  @media (max-width: 1500px) {
    width: 90%; } }


.logo {
  width: 303px;
  margin-bottom: 25px; }

.linkTab {
  color: #363636;
  text-decoration: none;
  font-size: 24px;
  opacity: 0.2;
  font-weight: bold;
  transition: opacity;
  transition-duration: .5s; }

.linkTitle {
  width: 50%;
  min-width: fit-content;
  text-align: center;
  cursor: pointer; }

.linkTabActive {
  opacity: 1;
  cursor: default; }

.containerPart {
  width: 50%;
  height: 100vh;
  position: relative;
  @media (max-width: 1100px) {
    width: 75%; } }


.leftPart {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  background: #FBCD2F;
  @media (max-width: 1100px) {
    display: none !important; } }

.rightPart {
  box-sizing: border-box;
  background: #FFFFFF;
  flex-direction: column;
  padding-bottom: 25px;
  @media (max-width: 1100px) {
    justify-content: flex-start !important; } }

@media (max-height: 821px) {
  .formContainer {
    transform: scale(0.8); } }

.marketsBar {
  //margin-top: 10px
  position: absolute;
  bottom: 2%; }

.postContainer {
  width: 540px;
  min-height: 468px;
  background: white;
  border-radius: 20px;

  .image {
    width: 100%;
    height: 400px;
    border-radius: 20px;
    object-fit: cover; }

  .authorBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 7px 30px;

    .likeBox {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 65px;
      height: 65px;
      box-sizing: border-box;
      border-radius: 100px;
      background: #9597a1;
      background: rgba(149, 151, 161, 0.08); }

    .likeCount {
      padding-right: 45px;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 26px;
      display: flex;
      align-items: center;
      color: #9597A1;
      margin-left: 25px; }

    .nickname {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 26px;
      text-align: right;
      letter-spacing: 0.01em;
      color: #13267E;
      margin-left: 15px; }

    .avatar {
      border-radius: 100px; } } }


.topLegs {
  padding-right: calc((100% - 540px) / 2);
  align-self: flex-end; }


.bottomLegs {
  padding-left: calc((100% - 540px) / 2);
  align-self: flex-start; }

@media (max-height: 900px) {
  .topLegs, .bottomLegs {
    display: none; }
  .leftPart {
    justify-content: center; } }
