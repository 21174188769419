.bar {
  width: 24%;
  position: fixed;
  top: 100px;
  padding-top: 24px;
  @media (max-width: 1300px) {
    padding-top: 0;
    top: 0;
    position: initial;
    margin: 0 auto 20px;
    min-width: 340px;
    width: 70%; } }

.leftBar {
  @extend .bar;
  top: 124px;
  width: 24%;
  padding: 40px;
  min-height: 247px;
  @media (max-width: 1300px) {
    margin: 0 auto 20px;
    min-width: 340px;
    width: 70%; }

  @media (max-width: 1300px) {
    left: 0; } }

.rightBar {
  @extend .bar;
  right: 6%;
  @media (max-width: 1300px) {
    right: 0!important; } }

.userInfo {
  width: 100%;
  @media (max-width: 1300px) {
    width: 50%; } }

.emptyLabel {
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  color: #13267E; }

.publicationsContainer {
  display: flex;
  padding: 24px 6%;
  width: 100%;
  height: fit-content;
  overflow: auto;
  @media (max-width: 1300px) {
    flex-direction: column;
    align-items: center; }

  .leftBar {
    border-radius: 20px;
    background: #FFFFFF;

    .button {
      text-align: center;
      padding: 14px 0;
      border-radius: 100px;
      border: none;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 21px;
      letter-spacing: 0.01em;
      color: #FFFFFF;
      cursor: pointer;
      transition: background-color;
      transition-duration: .7s;

      &:focus {
        outline: none;
        border: none; } }

    .addButton {
      @extend .button;
      background-color: #098B57;

      &:hover {
        background-color: #076b43; } }

    .blockButton {
      @extend .button;
      background-color: #F70503;

      &:hover {
        background-color: #b00100; } }

    .avatar {
      min-width: 78px; }

    .userNameWrap {
      @media (max-width: 1300px) {
        min-width: 48%; } }

    .userName {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      color: #13267E;
      margin-bottom: 8px; }

    .friendIconBox {
      margin-right: 66px;
      margin-left: 13px; }

    .subText {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      color: #C4C4C4;
      word-break: break-word; }

    .friendsIcon {
      width: 23px;
      margin-top: -3px; }

    .friendsBadge {
      display: inline-block;
      min-width: 28px;
      padding: 3px;
      border-radius: 50%;
      text-align: center;
      background: #fbcd2f;
      color: #fefefe;
      height: 28px;
      margin-top: -4px;
      line-height: 22px; } }

  .privatePopup {
    min-height: 606px;
    padding: 140px 0 104px; }

  .mainBox {
    padding: 23px 35px;
    width: 40%;
    min-width: 340px;
    min-height: 606px;
    margin: 0 auto 50px;
    overflow: auto;
    @media (max-width: 1300px) {
      margin: 0 auto 20px;
      width: 70%; }
    background: #FFFFFF;
    border-radius: 20px;
    @media (max-width: 1240px) {
      width: 70%; }

    .privatTextBox {
      text-align: center;
      width: 414px;
      max-height: 46px;
      word-break: break-word;
      margin-top: 105px;

      .text {
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 23px;
        color: #C4C4C4; } }

    .blockBox {
      background: linear-gradient(180deg, #F6D668 0%, rgba(255, 255, 255, 0) 100%), #FCCE30;
      border-radius: 50px;
      padding: 60px 50px;
      text-align: center;
      position: relative;

      .title {
        font-style: normal;
        font-weight: 500;
        font-size: 22px;
        color: #1E1E1E; }

      .img {
        width: 86px;
        height: 86px;
        left: calc(50% - 43px);
        position: absolute;
        top: -43px; } }


    .friendsBox {
      box-sizing: border-box;

      .link {
        text-decoration: none;

        &:hover .name {
          text-decoration: underline; } }

      .usersContainer {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 30px;

        &::after {
          content: "";
          flex: auto; } }

      .title {
        width: 100%;
        word-break: break-all;
        font-style: normal;
        font-weight: 500;
        font-size: 22px;
        color: #13267E; }


      .userItem {
        width: calc(100% * (1 / 4));

        &:nth-child(n+5) {
          margin-top: 35px; }
        .avatar {
          border-radius: 100px; }

        .name {
          margin-top: 10px;
          text-decoration: none;
          font-style: normal;
          font-weight: 500;
          font-size: 20px;
          line-height: 26px;
          color: #363636; } } } } }


.haveNotFriendsBox {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  .text {
    margin-top: 5%;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    color: #C4C4C4; } }
