.container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  @media (max-width: 1120px) {
    flex-wrap: wrap;
    justify-content: center; } }


.publicationsContainer {
  padding: 24px 6%;
  width: 100%;
  overflow: auto;
  @media (max-width: 1300px) {
    height: initial; } }

.publicationsWrapper {
  width: 40%;
  min-width: 340px;
  margin: 0 auto 50px;
  @media (max-width: 1300px) {
    margin: 0 auto 20px;
    width: 70%; } }

.barContainer {
  width: 22%;
  min-width: 320px;
  position: fixed;
  left: 57%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 200px);
  @media (max-width: 900px) {
    justify-content: flex-start; }
  @media (max-width: 1100px) {
    position: relative;
    width: 50%;
    margin: 0 auto;
    left: 0;
    height: fit-content; } }

.bar {
  width: 24%;
  position: fixed;
  top: 100px;
  padding-top: 24px;
  @media (max-width: 1300px) {
    padding-top: 0;
    position: initial;
    margin: 0 auto 20px;
    min-width: 340px;
    width: 70%; } }

.leftBar {
  @extend .bar;
  @media (max-width: 1300px) {
    left: 0; } }


.rightBar {
  @extend .bar;
  right: 6%;
  @media (max-width: 1300px) {
    right: 0!important; } }

