.emptyMainBox {
  width: 100%;

  .emptyLabel {
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    color: #13267E; }

  .placeText {
    margin: 5% 0;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    color: #C4C4C4; } }
