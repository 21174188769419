.background {
  background: #FEEB9F; }

.profileLink {
  display: flex;
  align-items: center;
  text-decoration: none;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  text-align: right;
  letter-spacing: 0.01em;
  color: #13267E;
  &:hover {
    text-decoration: none; } }


.content {
  width: 100%;
  height: calc(100vh - 100px);
  position: fixed;
  background: #FEEB9F;
  z-index: 1; }

.overflow {
  overflow: auto; }

.headerContainer {
  position: relative;
  width: 100%;
  min-height: 100px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 24px 6%;
  box-sizing: border-box;
  background: #FBCD2F;
  -webkit-box-shadow: 0px 5px 6px 0px rgba(81, 81, 81, 0.5);
  -moz-box-shadow: 0px 5px 6px 0px rgba(81, 81, 81, 0.5);
  box-shadow: 0px 5px 6px 0px rgba(81, 81, 81, 0.5);
  z-index: 5;
  @media (max-width: 1300px) {
    flex-wrap: wrap;
    justify-content: center !important; } }

.dropdownIcon {
  width: 12px;
  height: 6px; }

.toggle {
  &:after {
    display: none; } }

.avatarContainer {
  width: 24%;
  @media (max-width: 1300px) {
    width: 35%;
    margin-bottom: 15px; } }

.avatar {
  .name {
    min-width: fit-content;
    text-align: left;
    &:hover {
      text-decoration: underline; } } }

.foot {
  width: 42px;
  height: 39px; }

.logo {
  width: 160px;
  height: 39px; }

.inputBox {
  width: 40%;
  margin: 0 auto;
  @media (max-width: 1300px) {
    order: 1;
    width: 70%; } }

.inputContainer {
  width: 100%;
  height: 52px;
  background: #F9F9FB;
  padding: 17px 17px;
  box-sizing: border-box;
  border-radius: 8px;
  @media (max-width: 1100px) {
    width: 100%;
    margin-left: 0; } }

.input {
  border: none;
  background: #F9F9FB;
  width: 100%;
  margin-left: 14px;

  &:focus {
    border: none;
    outline: none; } }
