.container {
  width: 450px;
  background: #FFFFFF;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 45px 50px 35px 50px; }

.textAlignCenter {
  text-align: center; }

.fullWidth {
  width: 100%; }

.halfWidth {
  width: 50%; }

.flexJustifyAround {
  display: flex;
  justify-content: center; }

.orBox {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px; }

.pageType {
  font-size: 24px;
  opacity: 0.2;
  cursor: pointer;
  font-weight: bold;
  transition: opacity;
  transition-duration: .5s; }

.pageType[datatype='active'] {
  opacity: 1;
  cursor: default; }

.horizontalLine {
  height: 0;
  width: 170px;
  border: 0.25px solid #000000;
  box-sizing: border-box;
  opacity: 0.1;
  display: flex;
  justify-content: space-around;
  align-items: center; }

.buttonContainer {
  margin-top: 48px;
  text-align: center; }

.forgotPasswordBox {
  margin-top: 20px;
  display: flex;
  justify-content: center; }

.forgotPasswordText {
  text-decoration: none;
  color: #363636;
  &:hover {
    text-decoration: underline; } }


.forgotPasswordHelpText {
  width: 341px;
  margin: 10px auto; }
