.container {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: linear-gradient(180deg, #F6D668 0%, rgba(255, 255, 255, 0) 100%), #fbcd2f;
  border-radius: 100px;
  border: none;
  transition: background-color;
  transition-duration: 0.5s;
  &:focus {
    outline: none; }
  &:hover {
    background: linear-gradient(180deg, #f8d454 0%, rgba(255, 255, 255, 0) 100%), #e9be1d; } }

.buttonText {
  font-size: 18px;
  color: #FFFFFF;
  margin-right: 12px; }

.buttonImg {
  width: 43px; }
