.container {
  word-break: break-word;
  &:first-child {
    margin-top: 0; }
  .timeAgo {
    left: 12%;
    bottom: -20px;
    position: absolute;
    font-style: normal;
    font-weight: normal;
    font-size: 9px;
    line-height: 12px;
    color: #C4C4C4; }
  .timeAgoReply {
    @extend .timeAgo;
    position: relative;
    bottom: 0;
    left: 0;
    margin-top: 5px; }
  .avatar {
    border-radius: 50%;
    max-height: 40px;
    max-width: 40px;
    margin-right: 10px;
    font-size: 16px;
    span {
      font-size: 17px;
      font-weight: 500; } }
  .commentBox {
    width: 100%;
    position: relative;
    .userName {
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      display: flex;
      align-items: center;
      color: #363636; }
    .userNameReply {
      @extend .userName;
      margin-bottom: 5px; }
    .commentBody {
        @extend .userName;
        max-width: 80%;
        font-weight: normal;
        font-size: 12px;
        color: #000000; }
    .reply {
      position: absolute;
      right: 0;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      color: #9597A1;
      cursor: pointer;
      &:hover {
        text-decoration: underline; } } }

  .replies {
    flex-direction: column;
    padding-left: 60px;
    width: 100%; }

  .link {
    max-width: fit-content;
    text-decoration: none;
    &:hover {
      text-decoration: underline; } } }


