.container {
  width: 40%;
  padding: 22px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #F6F6F6;
  border-radius: 100px;
  cursor: pointer;
  transition: background-color;
  transition-duration: .5s;
  &:hover {
    background: #d7d5d5; }
  .text {
    font-family: "DM Sans", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #000000;
    margin-left: 13px; } }
