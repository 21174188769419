.skeleton {
  width: 100%;
  border-radius: 20px;
  background: #FFFFFF;
  padding: 22px 18px 15px 18px;
  box-sizing: border-box;
  margin-bottom: 50px;
  .photo {
    border-radius: 10px;
    margin: 10px 0px; }
  .text {
    height: 7px;
    border-radius: 10px; } }
