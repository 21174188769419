.fieldContainer {
  position: relative; }

.fieldBox {
  width: 100%;
  background: #F6F6F6;
  border-radius: 100px;
  font-size: 18px;
  padding-top: 17px;
  padding-bottom: 17px;
  text-align: center;
  border: none;
  box-sizing: border-box;
  margin-top: 38px;
  &:focus {
    outline: none; } }

.errorText {
  color: red; }

.errorBox {
  opacity: 0;
  position: absolute;
  left: 5%;
  bottom: -30px;
  transition: opacity;
  transition-duration: .5s; }

.visible {
  opacity: 1; }

