.container {
  background: #FFFFFF;
  border-radius: 20px;
  width: 100%;
  min-height: 360px;
  max-height: fit-content;
  padding: 25px 35px 30px;
  box-sizing: border-box;
  .title {
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    color: #13267E; } }

.peopleList {}

.peopleItem {
  width: 100%;
  .link {
    text-decoration: none; }
  .name {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #363636;
    &:hover {
      text-decoration: underline; } }
  .avatar {
    border-radius: 50px; }
  .button {
    background: #098B57;
    border-radius: 100px;
    padding: 9px 30px;
    box-sizing: border-box;
    border: none;
    cursor: pointer;
    color: #FFFFFF;
    &:hover {
      background: #057549; }
    &:focus {
      outline: none; } } }
