.container {
  width: 450px;
  .link {
    text-decoration: none; } }


@media (max-height: 821px) {
  .container {
    transform: scale(0.8); } }


@media (max-height: 744px) {
  .container {
    transform: scale(0.0); } }


.icon {
  height: 42px; }

.marketButton {
  width: 175px;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
  background: rgba(252, 206, 48, 0.74);
  border-radius: 30px;
  transition: background-color;
  transition-duration: 0.5s;
  &:hover {
    background: linear-gradient(180deg, #f8d454 0%, rgba(255, 255, 255, 0) 100%), #e9be1d; } }

.textContainer {
  display: flex;
  flex-direction: column;
  align-items: center; }

.marketLabel {
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #FFFFFF;
  white-space: nowrap; }

.marketName {
  @extend .marketLabel;
  font-size: 18px;
  font-style: italic; }
