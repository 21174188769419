.container {
  position: relative;
  align-self: flex-start;
  background: #FFFFFF;
  border-radius: 20px;
  width: 100%;
  padding: 25px;
  box-sizing: border-box;
  -webkit-box-shadow: 0px 7px 10px 0px rgba(69, 69, 69, 0.7);
  -moz-box-shadow: 0px 7px 10px 0px rgba(69, 69, 69, 0.7);
  box-shadow: 0px 7px 10px 0px rgba(69, 69, 69, 0.7);
  min-height: 287px;

  .title {
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    color: #13267E;
    margin-left: 12px; }

  .link {
    text-decoration: none;

    &:hover .name {
      text-decoration: underline; }
    .name {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: #3D3D3D; } } }


.avatar {}

.friendsContainer {
  width: 100%;
  height: 100%;
  display: flex;
  margin-top: 30px;
  justify-content: flex-start; }

.placeholder {
  font-family: DM Sans, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  color: #C4C4C4;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -10%);
  width: 100%; }
