.button {
  min-width: 91px;
  max-width: 91px;
  min-height: 40px;
  padding: 10px 0;
  text-align: center !important;
  border-radius: 100px;
  border: none;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.01em;
  color: #FFFFFF;
  cursor: pointer;
  transition: background-color;
  transition-duration: .7s;
  background-color: #098B57;
  &:hover {
    background-color: #15593E; }
  &:focus {
    outline: none; } }

.avatar {
  border-radius: 100px; }

.name {
  text-decoration: none;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  color: #363636;
  &:hover {
    text-decoration: underline; } }
