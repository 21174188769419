html, body {
  height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

** {
  font-family: DM Sans, sans-serif;
}

.ta-c {
  text-align: center;
}

.height-f {
  height: 100%;
}

.height-h {
  height: 50%;
}

.width-f {
  width: 100%;
}

.width-q {
  width: 25%;
}

.width-h {
  width: 50%;
}

.flex {
  display: flex;
}

.flex-w {
  flex-wrap: wrap;
}

.flex-d-c {
  @extend .flex;
  flex-direction: column;
}

.flex-d-r {
  @extend .flex;
  flex-direction: row;
}

.flex-j-a {
  @extend .flex;
  justify-content: space-around;
}

.flex-j-b {
  @extend .flex;
  justify-content: space-between;
}

.flex-j-s {
  @extend .flex;
  justify-content: flex-start;
}

.flex-j-e {
  @extend .flex;
  justify-content: flex-end;
}

.flex-j-c {
  @extend .flex;
  justify-content: center;
}

.flex-a-c {
  @extend .flex;
  align-items: center;
}

.flex-a-s {
  @extend .flex;
  align-items: start;
}

.flex-a-b {
  @extend .flex;
  align-items: baseline;
}


.flex-centred {
  @extend .flex;
  @extend .flex-j-c;
  @extend .flex-a-c
}

@for $i from 1 through 10 {
  .mb-#{$i} {
    margin-bottom: #{$i*5}px;
  }
  .mt-#{$i} {
    margin-top: #{$i*5}px;
  }
  .ml-#{$i} {
    margin-left: #{$i*5}px;
  }
  .mr-#{$i} {
    margin-right: #{$i*5}px;
  }
}

.error-text {
  color: #F70503;
  width: 94%;
  margin: 5px auto 0;
  text-align: center;
}

.shadow {
  -webkit-box-shadow: 0px 7px 10px 0px rgba(69, 69, 69, 0.7);
  -moz-box-shadow: 0px 7px 10px 0px rgba(69, 69, 69, 0.7);
  box-shadow: 0px 7px 10px 0px rgba(69, 69, 69, 0.7);
}
