.root {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 20px;
  background: #FBCD2F;
  .title {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 31px;
    text-align: center;
    color: #000000; } }


.container {
  height: 55%;
  width: 70%;
  .link {
    text-decoration: none; } }


.icon {
  height: 42px; }

.marketButton {
  width: 175px;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
  background: #9597A1;
  border-radius: 30px;
  transition: background-color;
  transition-duration: 0.5s; }

.textContainer {
  display: flex;
  flex-direction: column;
  align-items: center; }

.marketLabel {
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color: #FFFFFF; }

.marketName {
  @extend .marketLabel;
  font-size: 18px;
  font-style: italic; }

.legBox {
  width: 100%;
  &[datatype='bottom'] {
    text-align: left; }
  &[datatype='top'] {
    text-align: right; }
  .top {
    text-align: right;
    margin-left: auto;
    width: 30%; }
  .bottom {
    margin-right: auto;
    width: 30%; } }

.legBox {}

@media (max-height: 820px) {
  .root {
    padding: 0; }
  .container {
    width: 100%; } }
